export const logEvent = (eventType: string) => {
  try {
    // @ts-ignore
    if (typeof Android !== "undefined" && Android !== null) {
      // @ts-ignore
      Android.logEvent(eventType);
      return;
    }
  } catch (err) {
    console.debug(`Android log error: ${err}`);
  }
  try {
    // @ts-ignore
    window.webkit.messageHandlers.observer.postMessage(eventType);
    return;
  } catch (err) {
    console.debug(`WebKit error: ${err}.`);
  }
};
