import { FunctionalComponent, h, createRef } from "preact";
import { useEffect, useState, useRef, useImperativeHandle } from "preact/hooks";
import Main from "./Main";
import TopControls from "./TopControls";
import Product from "../product/Product";
import data2 from "../../demodata/sample";
import { type Swiper as SwiperType } from "swiper";
import "../../index.css"; // TODO: test if this import is redundant
import Drawer from "@mui/material/Drawer";
import LoadingScreen from "../LoadingScreen";
import BrandRecallScreen from "../brand_recall/BrandRecallScreen";

interface Props {
  cid: number;
  os: string;
  isInterstitial: boolean;
}

// @ts-ignore
const Campaign: FunctionalComponent<Props> = (props: Props) => {
  const { cid, os, isInterstitial } = props;
  const [data, setData] = useState(undefined);
  const [productFlag, setProductFlag] = useState<boolean>(false);
  const vidRef = useRef(null);
  const mainRef = useRef<any>();
  const swiperRef = useRef<SwiperType>();
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(0);
  const [timerCompleted, setTimerCompleted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<string>("home");
  const mainWrapperRef = createRef();

  const [isMuted, setIsMuted] = useState<boolean>(false);
  useImperativeHandle(mainRef, () => ({
    unMuteWithFade: () => unmute(),
  }));
  const muteInstantly = () => {
    const video = vidRef.current;
    video.muted = true;
    setIsMuted(true);
  };
  const muteWithFade = () => {
    const fadeAudioUnmute = setInterval(() => {
      const video = vidRef.current;
      if (!video) return;
      const newVideoVolume = video.volume - 0.1;
      if (newVideoVolume < 0.1) {
        video.volume = 0;
        clearInterval(fadeAudioUnmute);
      } else {
        video.volume = newVideoVolume;
      }
    }, 40);
  };
  const unMuteInstantly = () => {
    const video = vidRef.current;
    video.muted = false;
    video.volume = 1;
    setIsMuted(false);
  };
  const unMuteWithFade = () => {
    const fadeAudioMute = setInterval(() => {
      const video = vidRef.current;
      video.muted = false;
      const newVideoVolume = video.volume + 0.1;
      if (newVideoVolume > 1) {
        video.volume = 1;
        clearInterval(fadeAudioMute);
      } else {
        video.volume = newVideoVolume;
      }
    }, 40);
  };

  const unmute = () => {
    unMuteWithFade();
    setIsMuted(false);
  };

  const mute = () => {
    muteWithFade();
    setIsMuted(true);
  };

  useEffect(() => {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      // @ts-ignore
      setData(data2);
    } else {
      fetch(`https://${process.env.REACT_APP_TEMPO_REST_ADS_API_URL}/json?cid=${cid}`)
        .then(res => {
          return res.json();
        })
        .then(d => {
          setData(d.data.campaign[0]);
        });
    }
    // fetch(`https://ads-api.dev.tempoplatform.com/json?cid=92`)
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then(d => {
    //     setData(d.data.campaign[0]);
    //   });
  }, []);

  const setActiveProduct = (activeComponent: number) => {
    setSelectedProductIndex(activeComponent);
    setProductFlag(true);
  };

  const closeActiveProduct = () => {
    setProductFlag(false);
  };
  if (!data) return <LoadingScreen />;

  if (data.ad_type === "brand_recall") return <BrandRecallScreen data={data} />;

  return (
    <div style={{ height: "100%" }}>
      {data && (
        <div style={{ height: "100%" }}>
          <div className="main-wrapper" ref={mainWrapperRef}>
            <Main
              setActiveComponent={setActiveProduct}
              data={data}
              vidRef={vidRef}
              setCurrentPage={setCurrentPage}
              selectedProductIndex={selectedProductIndex}
              setSelectedProductIndex={setSelectedProductIndex}
              mainRef={mainRef}
              isMuted={isMuted}
              mute={mute}
            />
          </div>
          {/* redirect_to_webpage ad type never shows the product drawer */}
          {data.ad_type !== "redirect_to_webpage" && (
            <Drawer
              anchor={"bottom"}
              open={productFlag}
              onClose={() => setProductFlag(false)}
              variant="persistent"
              elevation={20}
              PaperProps={{
                sx: {
                  height: "100%",
                },
              }}
            >
              <Product
                os={os}
                cid={cid}
                data={data}
                swiperRef={swiperRef}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                productFlag={productFlag}
                pid={selectedProductIndex}
                setActiveComponent={closeActiveProduct}
                selectedProductIndex={selectedProductIndex}
                setSelectedProductIndex={setSelectedProductIndex}
              />
            </Drawer>
          )}
          <TopControls
            data={data}
            vidRef={vidRef}
            timerCompleted={timerCompleted}
            setTimerCompleted={(value: boolean) => setTimerCompleted(value)}
            isInterstitial={isInterstitial}
            isMuted={isMuted}
            unMuteInstantly={unMuteInstantly}
            muteInstantly={muteInstantly}
          />
        </div>
      )}
    </div>
  );
};

export default Campaign;
