export const openURL = (urlString: string) => {
  console.log('openURL: urlString', urlString)
  try {
    // @ts-ignore
    if (typeof Android !== "undefined" && Android !== null) {
      // @ts-ignore
      Android.openUrlInExternalBrowser(urlString);
      return;
    }
  } catch (err) {
    console.debug(`Android openURL error: ${err}`);
  }
  try {
    // @ts-ignore
    window.webkit.messageHandlers.observer.postMessage(
      JSON.stringify({ msgType: "OPEN_URL_IN_EXTERNAL_BROWSER", url: urlString }),
    );
    return;
  } catch (err) {
    console.debug(`WebKit openURL error: ${err}.`);
  }
};



